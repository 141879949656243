export default () => ({
  details: [
    {
      type: "",
      contactId: "",
      fuente: "",
      appName: "",
      msnActivaDefault: "",
      nombre: "",
      email: "",
      ciudad: "",
      asunto: "",
      resultado: "",
      nota: "",
    },
  ],
  telefono: "",
  estado: "",
  status: true,
});
